'use client';

import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
import {
  Dialog,
  DialogTrigger,
  Modal,
  ModalOverlay,
  Button as RAButton,
} from 'react-aria-components';

import { Icon } from '@/components/icon';
import Drawer, {
  DrawerBody,
  DrawerHeader,
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

export const CampaignTermsAndConditionsModal = ({
  children,
  terms,
}: {
  children: ReactNode;
  terms: string;
}) => {
  const isMobile = useIsMobile();
  const t = useTranslations('campaignTermsAndConditionsModal');

  const termsAndConditionsText = t('termsAndConditions');

  return (
    <div>
      <DialogTrigger>
        {children}
        {isMobile ? (
          <Drawer>
            {() => {
              return (
                <>
                  <DrawerHeader title={termsAndConditionsText} />
                  <DrawerBody>
                    <div className="flex max-h-[60vh] min-h-[50vh] flex-col gap-8 whitespace-pre-wrap">
                      <p>{terms}</p>
                    </div>
                  </DrawerBody>
                </>
              );
            }}
          </Drawer>
        ) : (
          <ModalOverlay
            isDismissable
            className={cn(
              'data-[entering]:animate-in data-[entering]:fade-in',
              'data-[exiting]:animate-out data-[exiting]:fade-out',
              drawerModalClass.animation,
              'group fixed inset-0 grid bg-neutral-900 bg-opacity-70',
              'z-50 items-end',
            )}
          >
            <Modal
              className={cn(
                'data-[entering]:animate-in data-[exiting]:animate-out',
                'data-[entering]:fade-in data-[exiting]:fade-out',
                drawerModalClass.animation,
                'h-[50vh] w-[50vw] overflow-y-scroll rounded bg-white lg:self-center lg:justify-self-center',
              )}
            >
              <Dialog
                className="flex h-full w-full flex-col items-center p-8"
                data-testid="campaigns-terms-modal"
              >
                {({ close }) => (
                  <div className="flex h-full w-full flex-col gap-8">
                    <div className="flex justify-between">
                      <span
                        className="mt-2 font-bold text-heading-2"
                        data-testid="campaigns-terms-modal-title"
                      >
                        {termsAndConditionsText}
                      </span>
                      <RAButton
                        onPress={close}
                        data-testid="campaigns-terms-modal-close-button"
                      >
                        <Icon name="xmark" className="-mt-8 h-5 w-5" />
                      </RAButton>
                    </div>
                    <div
                      className="flex flex-col gap-8 whitespace-pre-wrap"
                      data-testid="campaigns-terms-modal-details"
                    >
                      <p>{terms}</p>
                    </div>
                  </div>
                )}
              </Dialog>
            </Modal>
          </ModalOverlay>
        )}
      </DialogTrigger>
    </div>
  );
};
