'use client';
import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import ImageWithFallback from '@/design-system-components/default-image/image-with-fallback';
import { CarouselItem } from '@/design-system-components/shared-carousel/carousel';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { getOptimizedImageUrl } from '@/utils/get-optimized-image-url/get-optimized-image-url';
import { PRODUCT_LOGOS_OPTIONS } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';

export function M2020OffersBanner({ tenantId }: { tenantId: string }) {
  const isM2020 = useFlagsReady('m2020_tenants');
  const title = 'Boost your Points balance';

  if (!isM2020) return null;

  const abcRewardsIds = [
    'a244e83a-25c2-45a9-b8d9-c492a626f808',
    '534ccddf-b54f-4e58-a347-8156a52217db',
    '389e87db-2ca1-4595-b75a-b96f8c0083f0',
    '4b1d322c-a109-43f8-a1d4-1e3da991f480',
    '4b81858a-dd3c-4e3d-a19f-711d4c75f4d8',
  ];

  const demo1Ids = [
    'd119609c-69c5-4dbd-965f-89854d9e2072',
    '3258b581-a8a5-4f29-8525-95ce23f11f0f',
    '49620406-1b97-435d-9c9d-f902c9060164',
    '2191f59c-2195-4ea4-bce7-0fd8eb185d1d',
    'ac6aca03-db5a-4ce4-80d3-feac8c70bd44',
  ];

  const ascendaDemoIds = [
    'bfc0e397-6644-445f-8fae-2da7a62631d2',
    '3258b581-a8a5-4f29-8525-95ce23f11f0f',
    '49620406-1b97-435d-9c9d-f902c9060164',
    '2191f59c-2195-4ea4-bce7-0fd8eb185d1d',
    'ac6aca03-db5a-4ce4-80d3-feac8c70bd44',
  ];

  const computedIds =
    tenantId === 'demo1RcUs'
      ? demo1Ids
      : tenantId === 'ascenda-demoRcUs'
      ? ascendaDemoIds
      : abcRewardsIds;

  return (
    <HomepageProductsCarousel
      className={cn('gap-4 container-responsive lg:gap-6')}
      viewableClassName="mx-[-112px] px-[112px]"
      carouselWrapperClassName="gap-4 lg:gap-6"
      titleComponent={
        <p className="font-heading text-base-bold md:text-xl-bold">{title}</p>
      }
    >
      <CarouselItem onTouchStart={(e) => e.stopPropagation()}>
        <MyRcLink
          productLink={`/products/offers/${computedIds[0]}?source=online`}
          productImageUrl={
            'https://rc-assets.kaligo-staging.xyz/images-proxy/rc/default/images/m2020/shake_shack_1.png'
          }
          productName={'Shake Shack'}
          description={'Earn up to 5X per $1'}
        />
      </CarouselItem>

      <CarouselItem onTouchStart={(e) => e.stopPropagation()}>
        <MyRcLink
          productLink={`/products/offers/${computedIds[1]}?source=online`}
          productImageUrl={
            'https://rc-assets.kaligo-staging.xyz/images-proxy/rc/default/images/m2020/steam.jpg'
          }
          productName={'Steam'}
          description={'Earn up to 20X per $1'}
        />
      </CarouselItem>

      <CarouselItem onTouchStart={(e) => e.stopPropagation()}>
        <MyRcLink
          productLink={`/products/offers/${computedIds[2]}?source=online`}
          productImageUrl={
            'https://rc-assets.kaligo-staging.xyz/images-proxy/rc/default/images/m2020/sephora.jpg'
          }
          productName={'Sephora'}
          description={'Earn up to 10X per $1'}
        />
      </CarouselItem>

      <CarouselItem onTouchStart={(e) => e.stopPropagation()}>
        <MyRcLink
          productLink={`/products/offers/${computedIds[3]}?source=online`}
          productImageUrl={
            'https://rc-assets.ascenda.com/images-proxy/rc/default/assets/images/rc-us/m2020/nike_image.png'
          }
          productName={'Nike'}
          description={'Earn up to 5X per $1'}
        />
      </CarouselItem>

      <CarouselItem onTouchStart={(e) => e.stopPropagation()}>
        <MyRcLink
          productLink={`/products/offers/${computedIds[4]}?source=online`}
          productImageUrl={
            'https://rc-assets.ascenda.com/images-proxy/rc/default/assets/images/rc-us/m2020/instacart_image.png'
          }
          productName={'Instacart'}
          description={'Earn up to 10X per $1'}
        />
      </CarouselItem>
    </HomepageProductsCarousel>
  );
}

function MyRcLink({
  productLink,
  className,
  onClick,
  productImageUrl,
  productName,
  description,
}: {
  productLink: string;
  className?: string;
  onClick?: () => void;
  productImageUrl: string;
  productName: string;
  description: string;
}) {
  return (
    <RcLink
      href={productLink}
      className={cn(
        'group flex w-[200px] flex-col items-start gap-2 lg:w-[286px]',
        className,
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'relative flex shrink-0 flex-col items-center justify-center overflow-hidden',
          'lg:w-[286px]',
          'lg:h-[168px]',
          'h-[120px]',
          'w-[200px]',
          'rounded-custom bg-neutral-200',
        )}
      >
        <ImageWithFallback
          src={getOptimizedImageUrl({
            url: productImageUrl,
            ...PRODUCT_LOGOS_OPTIONS,
          })}
          alt=""
          className={cn('h-full w-full object-cover')}
        />
        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-200 group-hover:opacity-30 group-active:opacity-50" />
      </div>
      <div className="flex h-full flex-col items-start text-left">
        <div className="flex items-center justify-start gap-2 text-orchid-500">
          <Icon name="gift" className="h-4 w-4" />
          <p className="line-clamp-2 text-sm-normal">{description}</p>
        </div>

        <p className="line-clamp-1 text-base font-bold !text-neutral-900">
          {productName}
        </p>
      </div>
    </RcLink>
  );
}
