'use client';
import { flightRoutes } from '@/app/[locale]/travel/flights/routes';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { CarouselConfig } from '@/config-schema/home-page';
import ImageWithFallback from '@/design-system-components/default-image/image-with-fallback';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/design-system-components/shared-carousel/carousel';
import { CarouselArrowWithPagination } from '@/design-system-components/shared-carousel/carousel-arrow-with-pagination';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { getOptimizedImageUrl } from '@/utils/get-optimized-image-url/get-optimized-image-url';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';
import { cva } from 'class-variance-authority';

const variantClass = cva('', {
  variants: {
    textTransform: {
      uppercase: 'uppercase',
      capitalize: 'capitalize',
      lowercase: 'lowercase',
      none: '',
    },
  },
});

export const CarouselV2 = ({
  data,
  className,
}: Omit<CarouselConfig, 'type'> & { className?: string }) => {
  const isMobile = useIsMobile();
  const isM2020 = useFlagsReady('m2020_tenants');
  const hideIndicatorButton = data.length <= 1;

  return (
    <div className="bg-neutral-200">
      <Carousel
        className={cn('py-10 lg:container-responsive', className)}
        opts={{
          loop: false,
          watchDrag: true,
          breakpoints: {
            '(min-width: 1024px)': {
              watchDrag: false,
            },
          },
        }}
      >
        <CarouselContent viewableClassName="lg:mx-[88px]">
          {data.map(({ body, title, image }, index) => (
            <CarouselItem
              key={index}
              className="flex w-full shrink-0 flex-col lg:flex-row lg:gap-10"
            >
              <ImageWithFallback
                className={cn(
                  'h-[200px] object-cover lg:h-[248px] lg:rounded-custom',
                )}
                src={getOptimizedImageUrl({
                  url: image.url,
                  height: isMobile ? 200 : 248,
                })}
                alt={image.alt}
                height={isMobile ? 200 : 248}
              />

              <div className="flex h-full flex-col gap-2 p-4 lg:p-0">
                <p
                  className={cn(
                    'font-heading text-heading-2',
                    variantClass({ textTransform: title.textTransform }),
                  )}
                >
                  {title.content}
                </p>
                <p
                  className={cn(
                    variantClass({
                      textTransform: body.textTransform,
                    }),
                    'text-neutral-600',
                  )}
                >
                  {body.content}
                </p>

                {isM2020 ? (
                  <RcLink
                    href={flightRoutes.home}
                    className="justify-left flex flex-row items-center gap-2 text-shakespeare-500"
                  >
                    <p className="text-base-normal">Redeem now</p>
                    <Icon name="chevron-right" className="h-4 w-4" />
                  </RcLink>
                ) : null}

                <CarouselArrowWithPagination
                  currentIndex={index}
                  className={cn('ml-auto mt-auto lg:ml-0', {
                    hidden: hideIndicatorButton,
                  })}
                  showPagination
                />
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};
