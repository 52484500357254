import { useTranslations } from 'next-intl';
import type { ComponentProps } from 'react';

import { DiscountTag } from '@/components/discount-tag';
import { RcLink } from '@/components/rc-link';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import {
  PRODUCT_TILE_2_IMAGE_HEIGHT,
  PRODUCT_TILE_2_IMAGE_HEIGHT_MOBILE,
  PRODUCT_TILE_2_IMAGE_WIDTH,
  PRODUCT_TILE_2_IMAGE_WIDTH_MOBILE,
} from '@/utils/constants';
import { getOptimizedImageUrl } from '@/utils/get-optimized-image-url/get-optimized-image-url';
import { PRODUCT_LOGOS_OPTIONS } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';
import { useIsMobile } from '@/utils/use-is-mobile';

interface ProductTile2Props
  extends Pick<ComponentProps<typeof RcLink>, 'className' | 'onClick'> {
  dataGtm?: string;
  link: string;
  productName: string;
  deliveryDuration: number;
  minimumRedemption: number;
  targetMinimumRedemption: number;
  imageUrl: string;
  denomination: number;
  targetDenomination: number;
  partnerCurrencyName: string;
  ['data-testid']?: string;
  redeemable: boolean;
  discountPercentage?: number;
  showLiveDemoBadge?: boolean;
}

/**
 *
 * Points Transfer Products
 */
export default function ProductTile2(props: ProductTile2Props) {
  const t = useTranslations('productTitle2');
  const pointsFormatter = usePointsFormatter();
  const isMobile = useIsMobile();

  const minimumRedemption = pointsFormatter(props.minimumRedemption);
  const targetMinimumRedemption = pointsFormatter(
    props.targetMinimumRedemption,
    props.partnerCurrencyName,
  );

  const liveDemoText = t('liveDemo');
  const transferRatioText = t('transferRatio_min_targetMin', {
    min: minimumRedemption,
    targetMin: targetMinimumRedemption,
  });

  const imageHeight = isMobile
    ? PRODUCT_TILE_2_IMAGE_HEIGHT_MOBILE
    : PRODUCT_TILE_2_IMAGE_HEIGHT;
  const imageWidth = isMobile
    ? PRODUCT_TILE_2_IMAGE_WIDTH_MOBILE
    : PRODUCT_TILE_2_IMAGE_WIDTH;

  const redeemableM2020 = useFlagsReady('redeemable_m2020');
  const isM2020 = useFlagsReady('m2020_tenants');
  // We should error out if there is no transfer rate, as you cannot do a transfer if there is no transfer rate.
  // We can throw any error, because the "invalid data" has already been logged in `useLoyaltyPrograms` or any hook.
  if (!props.denomination || !props.targetDenomination) {
    throw new Error(
      `Loyalty Program ${props.productName} is missing transferRate denomination and targetDenomination`,
    );
  }

  return (
    <RcLink
      data-gtm={props.dataGtm}
      data-testid={props['data-testid']}
      href={props.link}
      className={cn('rounded', props.className)}
      onClick={props.onClick}
    >
      <div className="flex h-full flex-col items-center rounded-lg border border-neutral-400 bg-white hover:border-black">
        <div className="relative flex max-h-[179px] w-full items-center justify-center border-b border-neutral-400 p-6">
          {/* Need to manually add classes to override height and width because next Image height and width doesn't work */}
          {/* Cant use string interpolation with the constants here because tailwind classes won't work*/}
          <img
            className={cn(
              'mx-auto object-contain',
              'lg:h-[115px]',
              'lg:w-[184px]',
              'h-[149px]',
              'w-[247px]',
            )}
            height={imageHeight}
            width={imageWidth}
            src={getOptimizedImageUrl({
              url: props.imageUrl,
              ...PRODUCT_LOGOS_OPTIONS,
            })}
            alt=""
          />
          {props.redeemable && redeemableM2020 && props.showLiveDemoBadge ? (
            // this is a temporary solution for a Money 20/20 demo tenants
            // TODO: remove it later
            <span
              className={cn(
                'absolute left-6 top-4 bg-[#DFF3F9] text-sm font-normal text-[#05A0D1]',
                'flex rounded-full px-2',
              )}
            >
              {liveDemoText}
            </span>
          ) : null}
        </div>
        <div className="relative flex w-full flex-grow flex-col px-4 py-[20px] lg:p-6">
          <h2 className="mb-3 font-normal tracking-tight text-neutral-600">
            {props.productName}
          </h2>

          {props.discountPercentage ? (
            <DiscountTag
              discountPercentage={props.discountPercentage!}
              className="absolute -top-10"
            />
          ) : null}

          <p
            className={cn('mb-3 flex text-black', {
              'font-bold': !isM2020,
            })}
            aria-label={transferRatioText}
          >
            {minimumRedemption} = <br />
            {targetMinimumRedemption}
          </p>
        </div>
      </div>
    </RcLink>
  );
}
