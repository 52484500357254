'use client';

import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { useAvailableToRedeem } from '@/hooks/order_items/use-available-to-redeem';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import useProduct from '@/hooks/products/use-product';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { useRouter } from '@/i18n/navigation';
import { AvailableToRedeemItemType } from '@/schema/order-item.schema';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { ProductData } from '../redeem-again/redeem-again';
import { AvailableToRedeemProduct } from './available-to-redeem-product';
import { AvailableToRedeemSkeleton } from './available-to-redeem-skeleton';

const ProductNotAvailable = dynamic(
  () =>
    import('./../redeem-again/product-not-available').then(
      (module) => module.ProductNotAvailable,
    ),
  {
    ssr: false,
  },
);

export function AvailableToRedeem() {
  const router = useRouter();
  const t = useTranslations('availableToRedeem');

  const { data: pointsAccount } = usePointsAccounts();
  const pointsFormatter = usePointsFormatter();

  const { data, isError, isLoading } = useAvailableToRedeem();
  const [clickedProductData, setClickedProductData] = useState<
    ProductData | undefined
  >();

  const { status } = useProduct(
    clickedProductData?.productId || '',
    clickedProductData?.productType || 'gift_card',
    !!clickedProductData?.productId,
  );

  useEffect(() => {
    if (status === 'success' && clickedProductData?.link) {
      router.push(clickedProductData.link);
    }
  }, [status, clickedProductData, router]);

  const spendYourXPointsText = t('spendYourXPoints_pointsBalance', {
    pointsBalance: pointsFormatter(pointsAccount?.pointsBalance),
  });

  if (isLoading) {
    return <AvailableToRedeemSkeleton />;
  }

  if (isError || !data || data.length === 0) {
    return null;
  }

  return (
    <>
      <ProductNotAvailable
        open={status === 'error'}
        onClose={() => {
          setClickedProductData(undefined);
        }}
      />
      <HomepageProductsCarousel
        titleComponent={
          <h1 className="font-heading text-base-bold md:text-xl-bold">
            {spendYourXPointsText}
          </h1>
        }
        className="gap-6 container-responsive"
        viewableClassName="mx-[-112px] px-[112px]"
        carouselWrapperClassName="lg:gap-6 gap-4"
        hideOnNotSlidable
      >
        {data.map((item: AvailableToRedeemItemType) => (
          <AvailableToRedeemProduct
            key={item.productId}
            item={item}
            onClick={setClickedProductData}
          />
        ))}
      </HomepageProductsCarousel>
    </>
  );
}
