import { customNavigation } from '@/components/header/utils';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { QuickLinkGtmProps } from '@/config-schema/home-page';
import { MenuItemType } from '@/config-schema/navigation';
import { useTrackProductNavigation } from '@/hooks/amplitude/shared/use-track-product-navigation';
import { useTrackTravelNavigation } from '@/hooks/amplitude/travel/use-track-travel-navigation';
import { RedirectModalContext } from '@/root-provider/redirect-modal-provider';
import { cn } from '@/utils/tailwind';
import { useContext } from 'react';

export function QuickLinkItem({
  menuItem,
  className,
  titleClassName,
}: {
  menuItem: MenuItemType;
  className?: string;
  titleClassName?: string;
}) {
  const { setShowRedirectModal } = useContext(RedirectModalContext);
  const trackProductNavigation = useTrackProductNavigation();
  const trackTravelNavigation = useTrackTravelNavigation();
  const dataGtm = {
    event: 'homepage_v2_quick_link_click',
    group_0: menuItem.groupType,
    type_0: menuItem.itemType,
  } satisfies QuickLinkGtmProps;

  return (
    <RcLink
      href={menuItem.href}
      className={cn(
        'flex shrink-0 items-center justify-start gap-2',
        'gtm:homepage_v2_quick_link',
        className,
      )}
      data-gtm={JSON.stringify(dataGtm)}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        customNavigation(menuItem, event, setShowRedirectModal);
        trackProductNavigation(menuItem.itemType, 'quick_link');
        trackTravelNavigation(menuItem.itemType, 'quick_link');
      }}
    >
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-cloudburst-150 p-3">
        {menuItem.iconName ? (
          <Icon
            name={menuItem.iconName}
            className="h-5 w-5"
            width={20}
            height={20}
          />
        ) : null}
      </div>
      <p className={cn('text-center text-neutral-900', titleClassName)}>
        {menuItem.translation}
      </p>
    </RcLink>
  );
}
