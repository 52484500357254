'use client';
import { useFetch } from '@/hooks/fetch/use-fetch';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { giftCardByIdOptions } from '@/hooks/gift-cards/use-gift-cards';
import { loyaltyProgramByTypeAndIdOptions } from '@/hooks/loyalty-programs/use-loyalty-program-by-type-and-id';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { WontFix } from 'global';
import { useState } from 'react';
import { RecommendedBannerV2M2020 } from './m2020-recommended-banner-v2';

function useHardCodeDataData({ tenantId }: { tenantId: string }) {
  const queryClient = useQueryClient();
  const fetch = useFetch();

  const [demo1] = useState([
    loyaltyProgramByTypeAndIdOptions({
      id: '580a8f22-7e2b-48dc-96bc-b569c6b1b542',
      loyaltyProgramType: 'points_transfer',
      fetch,
      queryClient,
      enabled: true,
    }),
    loyaltyProgramByTypeAndIdOptions({
      id: 'a0c4795a-1bc1-4521-8032-aa5592880e4a',
      loyaltyProgramType: 'points_transfer',
      fetch,
      queryClient,
      enabled: true,
    }),
    loyaltyProgramByTypeAndIdOptions({
      id: 'e61e5245-7d16-4c02-aae6-0e25e9bff52d',
      loyaltyProgramType: 'voucher',
      fetch,
      queryClient,
      enabled: true,
    }),
    giftCardByIdOptions({
      id: '42c6eba9-059f-4d93-a2ce-c58d8868d4c4',
      fetch,
      queryClient,
      enabled: true,
    }),
  ]);

  const [ascendaDemo] = useState([
    loyaltyProgramByTypeAndIdOptions({
      id: '01e81189-a67f-4f59-bc3f-9b168be19144',
      loyaltyProgramType: 'points_transfer',
      fetch,
      queryClient,
      enabled: true,
    }),
    loyaltyProgramByTypeAndIdOptions({
      id: '22ab90d3-c97f-4667-ad35-148c8809938c',
      loyaltyProgramType: 'points_transfer',
      fetch,
      queryClient,
      enabled: true,
    }),
    loyaltyProgramByTypeAndIdOptions({
      id: '2c46671c-c435-402c-964e-74519e083aba',
      loyaltyProgramType: 'voucher',
      fetch,
      queryClient,
      enabled: true,
    }),
    giftCardByIdOptions({
      id: 'b7d2ba77-b582-4a57-9cdf-66395aaeb21d',
      fetch,
      queryClient,
      enabled: true,
    }),
  ]);

  const [demo2] = useState([
    loyaltyProgramByTypeAndIdOptions({
      id: '6940b38e-60db-4af0-9e39-d92c7c80ee74',
      loyaltyProgramType: 'points_transfer',
      fetch,
      queryClient,
      enabled: true,
    }),
    loyaltyProgramByTypeAndIdOptions({
      id: 'ee05605b-42f6-4e57-ac9c-e8e8a4024cf6',
      loyaltyProgramType: 'points_transfer',
      fetch,
      queryClient,
      enabled: true,
    }),
    loyaltyProgramByTypeAndIdOptions({
      id: 'b8e02939-9187-4c79-a1c7-af922629a5bc',
      loyaltyProgramType: 'voucher',
      fetch,
      queryClient,
      enabled: true,
    }),
    giftCardByIdOptions({
      id: 'dc27f066-b35e-40bc-8535-8eed10d24fe0',
      fetch,
      queryClient,
      enabled: true,
    }),
  ]);

  const [defaultData] = useState([]);

  if (tenantId === 'demo1RcUs') {
    return demo1;
  }

  if (tenantId === 'demo2RcUs') {
    return demo2;
  }

  if (tenantId === 'ascenda-demoRcUs') {
    return ascendaDemo;
  }

  return defaultData;
}

export function RecommendedBannerWithDataM2020({
  tenantId,
}: {
  tenantId: string;
}) {
  const isM2020 = useFlagsReady('m2020_tenants');

  return isM2020 ? <RecommendedBannerM2020 tenantId={tenantId} /> : null;
}

function RecommendedBannerM2020({ tenantId }: { tenantId: string }) {
  const queries = useHardCodeDataData({ tenantId });
  const results = useQueries({
    queries: queries as WontFix,
  });

  return (
    <RecommendedBannerV2M2020
      title="Inspiring ways to use your Points"
      results={results as WontFix}
    />
  );
}
