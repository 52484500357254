'use client';

import { useTitle } from 'ahooks';

interface PageTitleProviderProps {
  pageTitle: string;
}

/**
 * @description Component to override page title, can be used in both server / client components
 * @returns null
 */
export default function PageTitleProvider({
  pageTitle,
}: PageTitleProviderProps) {
  useTitle(pageTitle);

  return null;
}
