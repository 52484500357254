import { useQuery } from '@tanstack/react-query';

import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { queryKeys } from '@/queries/query-factory';
import {
  AvailableToRedeemItemsType,
  availableToRedeemItemsApiResponseSchema,
} from '@/schema/order-item.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { transformApi } from '@/utils/transform-api';
import { WontFix } from '../../../global';

const headers = new Headers();
headers.append('x-response-format', 'custom');

// This is a custom endpoint only for Available To Redeem section
export function useAvailableToRedeem() {
  const path = `${getClientUrl()}/api/order_items/available_to_redeem?`;
  const authedFetch = useAuthFetchKy;
  const hermes = useHermes();

  let queryParams = new URLSearchParams();

  return useQuery({
    ...queryKeys.availableToRedeem.list({}),
    select: (
      data: AvailableToRedeemItemsType,
    ): AvailableToRedeemItemsType['data'] => data.data,
    queryFn: async ({ signal }) => {
      const data = await authedFetch
        .get(`${path}${queryParams}`, { signal, headers })
        .json<WontFix>();

      const transformedData = transformApi(
        {
          ...data,
          meta: data.meta || { count: 0 },
        },
        {
          ignoreNotFoundId: true,
        },
      );

      return validateSchema({
        data: transformedData,
        schemaName: 'available-to-redeem-items-schema',
        zodSchema: availableToRedeemItemsApiResponseSchema,
      });
    },
    enabled: hermes.loggedIn,
  });
}
