import type { LoyaltyProgramType } from '@/schema/loyalty-program.schema';

export function getPathFromLoyaltyProgram(
  loyaltyProgramType: LoyaltyProgramType,
) {
  switch (loyaltyProgramType) {
    case 'points_transfer': {
      return '/products/frequent-traveler-programs/';
    }
    case 'cashback': {
      return '/products/cashback/';
    }
    case 'crypto': {
      return '/products/crypto/';
    }
    case 'sustainability': {
      return '/products/sustainability/';
    }
    case 'voucher': {
      return '/products/uber-credit/';
    }
    default: {
      return '';
    }
  }
}
