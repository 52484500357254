'use client';

import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { useHermes } from '@/auth/use-hermes';
import { Container } from '@/components/container';
import { Icon } from '@/components/icon';
import { RcLink } from '@/components/rc-link';
import { HomepagePointsBalanceWidgetConfig } from '@/config-schema/home-page';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';

export function PointsBalanceWidget(props: {
  config: HomepagePointsBalanceWidgetConfig['config'];
}) {
  const { data: pointsAccount } = usePointsAccounts();

  const pointsFormatter = usePointsFormatter();

  const { loggedIn } = useHermes();
  const t = useTranslations('homePagePointsBalanceWidget');

  const pointsValue = useMemo(() => {
    return pointsFormatter(pointsAccount?.pointsBalance);
  }, [pointsFormatter, pointsAccount?.pointsBalance]);

  const widgetContainerStyles = {
    backgroundColor: props.config?.backgroundColor,
    color: props.config?.textColor,
  };

  if (!loggedIn || !pointsValue) {
    return null;
  }

  const pointsBalanceText = t.rich('pointsBalance_value', {
    value: () => <strong>{pointsValue}</strong>,
  });
  const viewMyRewardsSummaryText = t('viewMyRewardsSummary');

  return (
    <div
      className="-mt-12 bg-white lg:-mt-[72px]"
      style={widgetContainerStyles}
    >
      <Container className="py-4">
        <div className="items-baseline lg:flex">
          <p className="mb-0 border-neutral-400 text-lg lg:mr-6 lg:border-r lg:pr-6">
            {pointsBalanceText}
          </p>
          <RcLink href="/rewards-summary/points-activity">
            {viewMyRewardsSummaryText}
            <Icon name="chevron-right" className="ml-2" />
          </RcLink>
        </div>
      </Container>
    </div>
  );
}
