'use client';

import { cn } from '@/utils/tailwind';
import { HTMLAttributes, forwardRef, useContext } from 'react';
import { CarouselContext } from './carousel';

const CarouselGradientLeft = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { api } = useContext(CarouselContext);

  return (
    <div
      ref={ref}
      className={cn(
        'absolute left-0 h-[44px] w-[120px] bg-gradient-to-l from-transparent to-white',
        { hidden: !api?.canScrollPrev() },
        className,
      )}
    />
  );
});
CarouselGradientLeft.displayName = 'CarouselGradientLeft';

const CarouselGradientRight = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const { api } = useContext(CarouselContext);

  return (
    <div
      ref={ref}
      className={cn(
        'absolute right-0 h-[44px] w-[120px] bg-gradient-to-r from-transparent to-white',
        { hidden: !api?.canScrollNext() },
        className,
      )}
    />
  );
});
CarouselGradientRight.displayName = 'CarouselGradientRight';

export { CarouselGradientLeft, CarouselGradientRight };
