import { LoyaltyProgramType } from '@/schema/loyalty-program.schema';
import { RedeemAgainOrderItem } from '@/schema/order/order-item';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useFetch } from '../fetch/use-fetch';
import { giftCardByIdOptions } from '../gift-cards/use-gift-cards';
import { loyaltyProgramByTypeAndIdOptions } from '../loyalty-programs/use-loyalty-program-by-type-and-id';

export type ProductType = LoyaltyProgramType | 'gift_card';

export const OrderItemTypeMapper = {
  crypto_order_item: 'crypto',
  cash_redemption_order_item: 'cashback',
  points_transfer_order_item: 'points_transfer',
  sustainability_order_item: 'sustainability',
  voucher_order_item: 'voucher',
  gift_card_order_item: 'gift_card',
} as const satisfies Record<RedeemAgainOrderItem['type'], ProductType>;

export default function useProduct(
  productId: string,
  productType: ProductType,
  enabled = true,
) {
  const fetch = useFetch();
  const queryClient = useQueryClient();

  const options = useMemo(() => {
    return productType === 'gift_card'
      ? giftCardByIdOptions({
          id: productId,
          fetch,
          queryClient,
          enabled,
        })
      : loyaltyProgramByTypeAndIdOptions({
          id: productId,
          loyaltyProgramType: productType,
          fetch,
          queryClient,
          enabled,
        });
  }, [productId, productType, fetch, queryClient, enabled]);

  const { queryKey, queryFn, ...remainingOptions } = options;

  return useQuery({
    queryKey,
    queryFn: async () => await queryFn(),
    ...remainingOptions,
  });
}
