import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { useQuery } from '@tanstack/react-query';
import { RecommendedProductsDataSchema } from '../types';

export function useRecommendedForUser() {
  const baseUrl = getClientUrl();
  const fetch = useAuthFetchKy;
  const { user } = useHermes();

  return useQuery({
    queryKey: ['recommendations', 'user', user?.sub],
    queryFn: async () => {
      const RECOMMENDED_FOR_USER_API = `${baseUrl}/api/recommendations/users/${user?.sub}`;
      const data = await fetch(RECOMMENDED_FOR_USER_API).then((res) =>
        res.json(),
      );

      return validateSchema({
        data,
        schemaName: 'recommended-products-schema',
        zodSchema: RecommendedProductsDataSchema,
      });
    },
    enabled: !!user?.sub,
  });
}
