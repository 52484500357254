import { RecommendedProductsDataItemTypes } from '@/features/recommendation/types';
import { z } from 'zod';
import { orderItemSchema, redeemAgainItemSchema } from './order/order-item';

export const orderItemsApiResponseSchema = z.object({
  data: z.array(orderItemSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export const redeemAgainItemsApiResponseSchema = z.object({
  data: z.array(redeemAgainItemSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export type RedeemAgainItemsType = z.infer<
  typeof redeemAgainItemsApiResponseSchema
>;

export const availableToRedeemItemSchema = z.object({
  productId: z.string().uuid(),
  productType: z.nativeEnum(RecommendedProductsDataItemTypes),
  countMarket: z.number(),
  maxProductValue: z.number(),
  productCurrency: z.string(),
  productImageUrl: z.string(),
  productName: z.string(),
  costInPoints: z.number(),
});

export type AvailableToRedeemItemType = z.infer<
  typeof availableToRedeemItemSchema
>;

export const availableToRedeemItemsApiResponseSchema = z.object({
  data: z.array(availableToRedeemItemSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export type AvailableToRedeemItemsType = z.infer<
  typeof availableToRedeemItemsApiResponseSchema
>;
