'use client';

import { useTranslations } from 'next-intl';

import { useIsTenantHub } from '@/hooks/config/tenants/use-is-tenanthub';
import { useTenantHubConfig } from '@/hooks/config/tenants/use-tenanthub-config';
import { useBootstrapData } from '@/store/store';
import { capitalizeFirstLetter } from '@/utils/text-utils';

import PageTitleProvider from './page-title-provider';

interface TenantHubPageTitleProviderProps {
  page: string;
}

export default function TenantHubPageTitleProvider({
  page,
}: TenantHubPageTitleProviderProps) {
  const isTenantHubTenant = useIsTenantHub();
  const tenantHubConfig = useTenantHubConfig();
  const bootstrapData = useBootstrapData();
  const t = useTranslations('metaTitle');

  const rewardsCentralText = t('rewardsCentral');

  const tenantName =
    tenantHubConfig?.company_name ??
    bootstrapData?.loyaltyPrograms?.at(0)?.name ??
    rewardsCentralText;
  const displayTenantName = capitalizeFirstLetter(tenantName);

  return isTenantHubTenant ? (
    <PageTitleProvider pageTitle={`${page} | ${displayTenantName}`} />
  ) : null;
}
