import { useTranslations } from 'next-intl';

import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { RcLink, RcLinkVariant } from '@/components/rc-link';
import { CarouselItem } from '@/design-system-components/shared-carousel/carousel';

export function RedeemAgainBannerSkeleton() {
  const t = useTranslations('redeemAgainBannerSkeleton');

  const redeemAgainText = t('redeemAgain');
  const viewAllRedemptionsText = t('viewAllRedemptions');

  return (
    <HomepageProductsCarousel
      className="gap-4 container-responsive"
      viewableClassName="mx-[-112px] px-[112px]"
      carouselWrapperClassName="gap-4"
      titleComponent={
        <div className="flex w-full items-center justify-between gap-4 lg:justify-start">
          <p className="font-heading text-base-bold md:text-xl-bold">
            {redeemAgainText}
          </p>
          <RcLink
            href="/rewards-summary/my-rewards"
            variant={RcLinkVariant.SECONDARY}
            className="text-sm text-secondary lg:text-base"
          >
            {viewAllRedemptionsText}
          </RcLink>
        </div>
      }
    >
      {Array.from({ length: 3 }).map((_, index) => (
        <CarouselItem key={index} className="shrink-0">
          <div className="h-[72px] w-[330px] animate-pulse rounded bg-neutral-300" />
        </CarouselItem>
      ))}
    </HomepageProductsCarousel>
  );
}
