import { useQuery } from '@tanstack/react-query';

import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import { queryKeys } from '@/queries/query-factory';
import {
  RedeemAgainItemsType,
  redeemAgainItemsApiResponseSchema,
} from '@/schema/order-item.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { transformApi } from '@/utils/transform-api';
import { WontFix } from '../../../global';

interface UseOrderItemsProps {
  pageSize?: number;
  pageNumber?: number;
}

// This is a custom endpoint only for Redeem Again section
// Only return non-travel and non-merchandise order items
export function useRedeemAgainItems({
  pageSize = 3,
  pageNumber = 1,
}: UseOrderItemsProps) {
  const path = `${getClientUrl()}/api/order_items/latest_redeemed_products?`;
  const authedFetch = useAuthFetchKy;
  const hermes = useHermes();

  let queryParams = new URLSearchParams();

  queryParams.set('page[number]', pageNumber.toString());
  queryParams.set('page[size]', pageSize.toString());
  queryParams.set('sort', '-created_at');

  return useQuery({
    ...queryKeys.redeemAgainItem.list({
      pageSize,
      pageNumber,
    }),
    select: (data: RedeemAgainItemsType): RedeemAgainItemsType['data'] =>
      data.data,
    queryFn: async ({ signal }) => {
      const data = await authedFetch
        .get(`${path}${queryParams}`, { signal })
        .json<WontFix>();

      const transformedData = transformApi(
        {
          ...data,
          meta: data.meta || { count: 0 },
        },
        {
          ignoreNotFoundId: true,
        },
      );

      return validateSchema({
        data: transformedData,
        schemaName: 'order-items-schema',
        zodSchema: redeemAgainItemsApiResponseSchema,
      });
    },
    enabled: hermes.loggedIn,
  });
}
