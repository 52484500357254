import { useTranslations } from 'next-intl';

import { cn } from '@/utils/tailwind';

import { Icon } from './icon';

export function DiscountTag({
  discountPercentage,
  className,
}: {
  discountPercentage: number;
  className?: string;
}) {
  const t = useTranslations('discountTag');

  const discountPercentageText = t('discountPercentage_amount', {
    /**
     * NOTE(sontruong):
     * discountPercentage: number
     * no need to check nullish value for discountPercentage
     * since it can't be null/undefined
     */
    amount: discountPercentage,
  });

  return (
    <span
      className={cn(
        'bg-primary-200 text-sm font-normal text-primary',
        'flex gap-2 rounded-full px-2',
        className,
      )}
    >
      <Icon name="gift" className="h-[14px] w-[14px] fill-primary" />
      {discountPercentageText}
    </span>
  );
}
