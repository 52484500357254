'use client';
import { useHermes } from '@/auth/use-hermes';
import { useAuthFetchKy } from '@/hooks/fetch/use-auth-fetch-ky';
import {
  CampaignsResponseSchema,
  campaignsResponseSchema,
} from '@/schema/campaigns/campaign.schema';
import { validateSchema } from '@/schema/validate-schema';
import { getClientUrl } from '@/utils/get-client-url';
import { transformApi } from '@/utils/transform-api';
import { useQuery } from '@tanstack/react-query';
import { WontFix } from 'global';

type UseCampaignsParams = {
  pageSize?: number;
  pageNumber?: number;
};

export function useCampaigns({
  pageSize = 5,
  pageNumber = 1,
}: UseCampaignsParams) {
  const baseUrl = getClientUrl();
  const authedFetch = useAuthFetchKy;
  const { loggedIn } = useHermes();

  let searchParams: URLSearchParams = new URLSearchParams({
    'page[size]': pageSize.toString(),
    'page[number]': pageNumber.toString(),
  });

  const path = `${baseUrl}/api/campaigns/active?${searchParams}`;

  return useQuery<CampaignsResponseSchema>({
    queryKey: ['campaigns'],
    queryFn: async () => {
      const data = await authedFetch.get(path).json();

      return validateSchema({
        schemaName: 'campaigns',
        data: transformApi(data as WontFix),
        zodSchema: campaignsResponseSchema,
      });
    },
    enabled: loggedIn,
  });
}
