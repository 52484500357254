import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';

export interface DefaultOfferLocation {
  city: string;
  lat: string;
  lon: string;
}

const LAS_VEGAS: DefaultOfferLocation = {
  city: 'Las Vegas, Nevada, United States',
  lat: '36.169212',
  lon: '-115.14062',
};
const NEW_YORK: DefaultOfferLocation = {
  city: 'New York City, New York, United States',
  lat: '40.7127753',
  lon: '-74.0059728',
};

export const useDefaultOfferLocation = () => {
  const isM2020 = useFlagsReady('m2020_tenants');
  if (isM2020) {
    return LAS_VEGAS;
  }
  return NEW_YORK;
};
