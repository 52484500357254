import { MenuItemType } from '@/config-schema/navigation';
import {
  Carousel,
  CarouselContent,
} from '@/design-system-components/shared-carousel/carousel';
import {
  CarouselNext,
  CarouselPrevious,
} from '@/design-system-components/shared-carousel/carousel-arrow';
import {
  CarouselGradientLeft,
  CarouselGradientRight,
} from '@/design-system-components/shared-carousel/carousel-gradient-effect';
import { cn } from '@/utils/tailwind';
import { QuickLinkItem } from './quick-link-item';

interface QuickLinksProps {
  headerLinks: MenuItemType[];
  className?: string;
}

export function QuickLinkV2({ className, headerLinks }: QuickLinksProps) {
  return (
    <Carousel
      className={cn(
        'relative flex items-center justify-between overflow-hidden',
        className,
      )}
      opts={{ slidesToScroll: 3, duration: 30, watchDrag: false }}
    >
      <CarouselContent className="gap-6" viewableClassName="mr-6">
        {headerLinks.map((menuItem) => (
          <QuickLinkItem menuItem={menuItem} key={menuItem.itemType} />
        ))}
      </CarouselContent>
      <CarouselGradientLeft />
      <CarouselGradientRight className="right-16" />

      <div className="flex">
        <CarouselPrevious
          variant="variant2"
          className="mr-2 text-neutral-900"
          iconColorOnDisabled="text-neutral-300"
        />
        <CarouselNext
          variant="variant2"
          className="gtm:homepage_arrow_next text-neutral-900"
          iconColorOnDisabled="text-neutral-300"
        />
      </div>
    </Carousel>
  );
}
