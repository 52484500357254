import { useMemo } from 'react';
import { useNumberFormatter } from 'react-aria';

import { Icon } from '@/components/icon';
import { PointsToRedeemSource } from '@/components/points-transfer-product';
import ImageWithFallback from '@/design-system-components/default-image/image-with-fallback';
import { useGiftCardCampaignsById } from '@/hooks/campaigns/use-gift-card-campaigns';
import { useFlagsReady } from '@/hooks/flags-ready/use-flags-ready';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';
import { GiftCardCampaignSchema } from '@/schema/campaigns/campaign.schema';
import { AvailableToRedeemItemType } from '@/schema/order-item.schema';
import { productTypeToTypeInPath } from '@/schema/order/common';
import { PRODUCT_LOGOS_OPTIONS } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';

import { getOptimizedImageUrl } from '@/utils/get-optimized-image-url/get-optimized-image-url';
import { CampaignDiscountLabel } from '../campaigns/campaign-discount-label';
import {
  getAppliedDenomination,
  getFeaturedCampaign,
} from '../campaigns/utils/gift-card-campaign-utils';
import { useProductTypeToRecommendationItemTitle } from '../recommendation/hooks/use-product-type-to-recommendation-item-title';
import { LoyaltyProgramTypeMapper } from '../recommendation/types';
import { ProductData } from '../redeem-again/redeem-again';

interface AvailableToRedeemProductProps {
  item: AvailableToRedeemItemType;
  onClick: (productData: ProductData) => void;
}
const emptyGiftCardCampaigns: GiftCardCampaignSchema[] = [];
const emptyAppliedDenominations: GiftCardCampaignSchema['appliedDenominations'] =
  [];

export function AvailableToRedeemProduct({
  item,
  onClick,
}: AvailableToRedeemProductProps) {
  const pointsFormatter = usePointsFormatter();
  const numberFormatter = useNumberFormatter();
  const gcCampaignsReleased = useFlagsReady('rc_giftcard_campaigns');

  const productTypeToRecommendationItemTitle =
    useProductTypeToRecommendationItemTitle();
  const { data } = useGiftCardCampaignsById({
    giftCardIds: [item.productId],
    enabled: gcCampaignsReleased && item.productType === 'GiftCard',
    select: (campaignMappings) => campaignMappings[item.productId],
  });

  const giftCardCampaigns =
    Array.isArray(data) && data.length > 0 ? data : emptyGiftCardCampaigns;

  const appliedDenomination = useMemo(() => {
    const appliedCampaign = getFeaturedCampaign(giftCardCampaigns);
    return appliedCampaign
      ? getAppliedDenomination({
          appliedDenominations:
            appliedCampaign.appliedDenominations ?? emptyAppliedDenominations,
          displayValue: item.maxProductValue,
        })
      : null;
  }, [giftCardCampaigns, item.maxProductValue]);

  const productData = getProductData(item);

  return (
    <button
      key={item.productId}
      className={cn('group flex h-auto w-[200px] flex-col gap-2 lg:w-[286px]')}
      onClick={() => {
        onClick(productData);
      }}
    >
      <div
        className={cn(
          'relative flex shrink-0 flex-col items-center justify-center overflow-hidden',
          'lg:w-[286px]',
          'lg:h-[168px]',
          'h-[120px]',
          'w-[200px]',
          'rounded-custom bg-neutral-200',
        )}
      >
        <ImageWithFallback
          src={getOptimizedImageUrl({
            url: item.productImageUrl,
            ...PRODUCT_LOGOS_OPTIONS,
          })}
          alt=""
          className={cn('h-full w-full object-contain', {
            'object-cover': productData.productType === 'gift_card',
          })}
        />
        <CampaignDiscountLabel
          className={cn(
            'absolute left-2 top-2 line-clamp-3 max-w-[calc(100%-16px)] text-left',
            {
              hidden: !appliedDenomination,
            },
          )}
          showTimeEnd={false}
          eligibleCampaigns={giftCardCampaigns}
        />
        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-200 group-hover:opacity-30 group-active:opacity-50" />
      </div>
      <div className="flex h-full flex-col items-start text-left">
        <p className="line-clamp-1 items-start text-base-bold">
          {productData.productType === 'gift_card'
            ? `${item.productCurrency}${item.maxProductValue} ${item.productName}`
            : `${numberFormatter.format(item.maxProductValue)} ${
                item.productName
              }`}
        </p>
        <p className="mt-auto pb-2 !text-neutral-600 text-base-normal">
          {productTypeToRecommendationItemTitle[item.productType]}
        </p>
        <div className="flex items-center gap-1 text-sm-normal">
          <Icon name="gem" />

          <p className="line-clamp-2">{pointsFormatter(item.costInPoints)}</p>
        </div>
      </div>
    </button>
  );
}

function getProductData(item: AvailableToRedeemItemType): ProductData {
  let productLink = [
    'products',
    productTypeToTypeInPath[item.productType],
    item.productId,
  ].join('/');

  const searchParams = new URLSearchParams();
  if (item.productType === 'GiftCard') {
    searchParams.set('pickedDenomination', item.maxProductValue.toString());
  } else {
    searchParams.set('pointsToRedeem', item.maxProductValue.toString());
    searchParams.set(
      'pointsToRedeemSource',
      'available_to_redeem' as PointsToRedeemSource,
    );
  }

  return {
    productType: LoyaltyProgramTypeMapper[item.productType] || 'gift_card',
    productId: item.productId,
    link: productLink + '?' + searchParams.toString(),
  };
}
