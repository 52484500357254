import { useTranslations } from 'next-intl';

import { PointsBalanceSkeleton } from '@/components/header/header-v2';
import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { CarouselItem } from '@/design-system-components/shared-carousel/carousel';
import { usePointsAccounts } from '@/hooks/points-accounts/use-points-accounts';
import { usePointsFormatter } from '@/hooks/utils/use-points-formatter';

export function AvailableToRedeemSkeleton() {
  const t = useTranslations('availableToRedeemSkeleton');
  const { data: pointsAccount } = usePointsAccounts();
  const pointsFormatter = usePointsFormatter();

  const availableToRedeemText = t.rich('availableToRedeem_points', {
    points: () =>
      pointsAccount ? (
        <p>{pointsFormatter(pointsAccount.pointsBalance)}</p>
      ) : (
        <PointsBalanceSkeleton />
      ),
  });

  return (
    <HomepageProductsCarousel
      className="gap-4 container-responsive"
      viewableClassName="mx-[-112px] px-[112px]"
      carouselWrapperClassName="gap-6"
      titleComponent={
        <h2 className="flex items-center gap-1 font-heading text-base-bold md:text-xl-bold">
          {availableToRedeemText}
        </h2>
      }
    >
      {Array.from({ length: 5 }).map((_, index) => (
        <CarouselItem
          key={index}
          className="flex w-[286px] shrink-0 flex-col gap-2"
        >
          <div className="h-[168px] w-full animate-pulse rounded bg-neutral-300" />
          <div className="h-4 w-[160px] animate-pulse rounded bg-neutral-300" />
          <div className="h-4 w-[160px] animate-pulse rounded bg-neutral-300" />
          <div className="h-4 w-[160px] animate-pulse rounded bg-neutral-300" />
        </CarouselItem>
      ))}
    </HomepageProductsCarousel>
  );
}
