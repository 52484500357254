'use client';

import { Container } from '@/components/container';
import { Markdown } from '@/components/markdown';
import { CopyConfig } from '@/config-schema/home-page';
import { WontFix } from 'global';

export default function Copy(props: CopyConfig['config']) {
  return (
    <div
      className="pb-8"
      style={{
        backgroundColor: props.backgroundColor,
        textTransform: props.textTransform ?? 'initial',
      }}
    >
      <Container>
        <Markdown
          overrides={{
            p: ({
              node,
              ...props
            }: {
              node: WontFix;
              props: React.HTMLAttributes<HTMLParagraphElement>;
            }) => <p className="mb-4 last-of-type:mb-0" {...props} />,
          }}
        >
          {props.content}
        </Markdown>
      </Container>
    </div>
  );
}
