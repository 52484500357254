import {
  BaseLoyaltyProgramCapability,
  LoyaltyProgramType,
  loyaltyProgramTypeSchema,
} from '@/schema/loyalty-program.schema';
import { Todo } from 'global';

export function getCapabilityForLoyaltyProgramType<
  T extends BaseLoyaltyProgramCapability,
>(input: LoyaltyProgramType, capabilities: Array<T>): T {
  switch (input) {
    case loyaltyProgramTypeSchema.enum.voucher:
    case loyaltyProgramTypeSchema.enum.crypto:
    case loyaltyProgramTypeSchema.enum.sustainability:
    case loyaltyProgramTypeSchema.enum.points_transfer: {
      return capabilities.find((c) => c.category === 'points_transfer')!;
    }
    case loyaltyProgramTypeSchema.enum.cashback: {
      return capabilities.find(
        (c) => c.category === 'cashback_to_platform' || 'cashback_to_tenant',
      )!;
    }
  }
}

export function getCapabilityConfig(
  attributeName: string,
  capability: Todo,
): Todo | null {
  if (!capability) {
    return null;
  }

  if (attributeName in capability.config) {
    return capability.config[attributeName];
  }
  return null;
}
