'use client';

import { useHermes } from '@/auth/use-hermes';
import { useRouter } from '@/i18n/navigation';
import { useShouldAllowHomeRedirect } from '@/store/store';
import { useEffect } from 'react';
import { flightRoutes } from './[locale]/travel/flights/routes';

export function HomePageRedirectLogic({
  shouldRedirect,
}: {
  shouldRedirect: boolean;
}) {
  const router = useRouter();
  const hermes = useHermes();
  const shouldAllowHomeRedirect = useShouldAllowHomeRedirect();

  useEffect(() => {
    if (shouldRedirect && hermes.authState === 'login') {
      // avoid redirecting to travel/flights for the first time
      // since the auth provider logic is already handled the initial redirect
      // otherwise the redirection logic would be broken
      // check apps/web/src/auth/redirect-logic.spec.ts for more details
      if (!shouldAllowHomeRedirect) {
        return;
      }

      // use replace to avoid redirect loop
      // for example A -> B -> C
      // if we use push in C, the user will be redirected to B
      // and then redirected to C again
      router.replace(flightRoutes.home);
    }
  }, [shouldRedirect, router, hermes.authState, shouldAllowHomeRedirect]);

  // We want to keep the home page as the server component so this component doesn't return anything
  return null;
}
