import type { GetOptimizedImageUrlInit } from './types';

// TC Images - ImgIx
const IMGIX_HOSTNAME: RegExp = /^.+imgix.net$/;

// RC Assets - CloudFlare: used for
// - Homepage Images
// - Help center, Terms & Conditions, etc. Images
const RC_ASSETS_HOSTNAME_LIST: RegExp[] = [
  /^rc-assets.ascenda.com$/,
  /^rc-assets.kaligo-staging.xyz$/,
  /^offershub-cdn.kaligo-staging.xyz$/,
];

// Travel optimized images service - CloudFlare: used for
// - Flight Images
// - Hotel Images
const TRAVEL_HOSTNAME_LIST: RegExp[] = [
  /^www.kaligo-staging.xyz$/,
  /^www.kaligo.com$/,
];

const CAR_HOSTNAME: RegExp = /^.+cartrawler.com$/;

const BASE_IMAGE_QUALITY = 100;
const DEFAULT_DEVICE_PIXEL_RATIO = 1;
const MAX_IMAGE_QUALITY = 90;
const QUALITY_REDUCTION_FACTOR = /** Any value between 0 and 20 but 15 is the sweet spot. */ 15;

function matchesAny(str: string, regexList: RegExp[]): boolean {
  return regexList.some((regex) => regex.test(str));
}

function getQuality(quality: GetOptimizedImageUrlInit['quality']): number {
  const dpr = globalThis.devicePixelRatio || DEFAULT_DEVICE_PIXEL_RATIO;
  const normalizedQuality = Math.max(
    Math.min(
      quality ||
        Math.min(
          MAX_IMAGE_QUALITY,
          BASE_IMAGE_QUALITY - (dpr - 1) * QUALITY_REDUCTION_FACTOR,
        ),
      100,
    ),
    1,
  );

  return normalizedQuality;
}

function normalizeFormatForImgix(
  format: GetOptimizedImageUrlInit['format'],
): string {
  if (!format || !['avif', 'jpeg', 'png', 'webp'].includes(format)) {
    /**
     * NOTE: Imgix will fallback to WebP or any other supported image format
     * if AVIF is not supported.
     */
    return 'avif';
  }

  return format === 'jpeg' ? 'jpg' : format;
}

export function getOptimizedImageUrl({
  url,
  ...props
}: GetOptimizedImageUrlInit) {
  try {
    const maybeUrl = url as string;
    const newUrl = new URL(maybeUrl);
    const { height, width, quality, format } = props;

    if (
      maybeUrl.startsWith('https://offershub-cdn.kaligo-staging.xyz/images')
    ) {
      newUrl.pathname = newUrl.pathname.replace('/images', '/images-proxy');
    }

    /**
     * note(@rongsen-kaligo): quality is set when width or height is not set.
     *
     * Tuning quality based on device pixel ratio can generate smaller yet visually similar images,
     * which is [better] than turning the image dimension to reduce the size but lose the quality.
     *
     * todo(@rongsen-kaligo): To progressively drop all width and height attributes in favor of quality.
     *
     * [better]: https://kaligo.atlassian.net/browse/RPD-414
     */
    const q = height || width ? undefined : getQuality(quality);

    if (matchesAny(newUrl.hostname, RC_ASSETS_HOSTNAME_LIST)) {
      return generateRCCloudFlareImageUrl({
        ...props,
        parsedUrl: newUrl,
        quality: q,
      });
    }

    if (matchesAny(newUrl.hostname, TRAVEL_HOSTNAME_LIST)) {
      return generateTravelImageUrl({
        ...props,
        parsedUrl: newUrl,
        quality: q,
      });
    }

    if (matchesAny(newUrl.hostname, [IMGIX_HOSTNAME])) {
      return generateImgixImageUrl({
        ...props,
        parsedUrl: newUrl,
        format: normalizeFormatForImgix(format),
        quality: q,
      });
    }

    if (matchesAny(newUrl.hostname, [CAR_HOSTNAME])) {
      return generateImgixImageUrl({
        parsedUrl: newUrl,
        width: 500,
        format: 'pjpg',
        usm: 11,
        usmrad: 16,
        shad: 15,
        dpr: 1,
      });
    }

    return newUrl.toString();
  } catch (error) {
    console.error(
      new Error(`Error generating image URL (${url})`, { cause: error }),
    );
    return '';
  }
}

interface GenerateParsedImageUrlProps
  extends Omit<GetOptimizedImageUrlInit, 'url'> {
  parsedUrl: URL;
}

function generateRCCloudFlareImageUrl({
  parsedUrl,
  width,
  height,
  fit,
  format,
  quality,
}: GenerateParsedImageUrlProps) {
  const optimizationParams = [
    ['width', width],
    ['height', height],
    ['fit', fit],
    ['format', format || 'avif'],
    ['quality', quality],
  ] as const;

  for (const [key, value] of optimizationParams) {
    if (value) {
      parsedUrl.searchParams.set(key, value.toString());
    }
  }

  return parsedUrl.toString();
}

function generateTravelImageUrl({
  parsedUrl,
  width,
  height,
  fit,
}: GenerateParsedImageUrlProps) {
  const optimizationParams = [
    ['w', width],
    ['h', height],
    ['fit', fit],
  ] as const;

  for (const [key, value] of optimizationParams) {
    if (value) {
      parsedUrl.searchParams.set(key, value.toString());
    }
  }

  return parsedUrl.toString();
}

function generateImgixImageUrl({
  parsedUrl,
  width,
  height,
  format,
  quality,
  usm,
  usmrad,
  shad,
  dpr,
}: {
  format: string;
  parsedUrl: URL;
  width?: number;
  height?: number;
  usm?: number;
  usmrad?: number;
  shad?: number;
  dpr?: number;
  quality?: number;
}) {
  const optimizationParams = [
    ['w', width],
    ['h', height],
    ['q', quality],
    ['auto', 'format'],
    ['fm', format || 'avif'],
    ['usm', usm],
    ['usmrad', usmrad],
    ['shad', shad],
    ['dpr', dpr],
  ] as const;

  for (const [key, value] of optimizationParams) {
    if (value) {
      parsedUrl.searchParams.set(key, value.toString());
    }
  }

  return parsedUrl.toString();
}
