import { useAppEnv } from '@/store/store';
import { getOptimizedImageUrl } from './get-optimized-image-url/get-optimized-image-url';
import type { GetOptimizedImageUrlInit } from './get-optimized-image-url/types';

/**
 * fixme: Move to hooks folder
 */
export const useTravelImageURLs = () => {
  const appEnv = useAppEnv();

  const flightsImagesUrl =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com/assets/images/flights/'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/flights/';

  const flightsCheckoutImagesUrl =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com/assets/images/checkout/'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/checkout/';

  const missingHotelImagesCDNUrl =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com/assets/images/hotels_missing_images/'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/hotels_missing_images/';

  const fallbackHotelImages = [`${missingHotelImagesCDNUrl}bell.jpg`];
  const fallbackRoomImages = [`${missingHotelImagesCDNUrl}pillow.jpg`];

  return {
    flightsImagesUrl,
    flightsCheckoutImagesUrl,
    fallbackHotelImages,
    fallbackRoomImages,
  };
};

export const useProductImageURLs = () => {
  const appEnv = useAppEnv();

  const appleFallbackImage =
    appEnv === 'production'
      ? 'https://cdn-s3.kaligo.com/assets/images/rc-us/notifications/apple-logo.png'
      : 'https://cdn-s3.kaligo-staging.xyz/assets/images/rc-us/notifications/apple-logo.png';

  return {
    appleFallbackImage,
  };
};

export const PRODUCT_LOGOS_OPTIONS = {
  width: 500,
  fit: 'contain',
} as const satisfies Pick<GetOptimizedImageUrlInit, 'fit' | 'width'>;

interface GenerateImagesUrlProps extends Omit<GetOptimizedImageUrlInit, 'url'> {
  urls: string[];
}

export function generateImagesUrl({ urls, ...props }: GenerateImagesUrlProps) {
  return urls.map((url) => getOptimizedImageUrl({ url, ...props }));
}
