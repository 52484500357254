import { HomepageProductsCarousel } from '@/components/homepage-products-carousel/homepage-products-carousel';
import { CarouselItem } from '@/design-system-components/shared-carousel/carousel';
import { getPathFromLoyaltyProgram } from '@/features/points-transfer/utils';
import { cn } from '@/utils/tailwind';
import { camelCaseToWords } from '@/utils/text-utils';
import { useRecommendedProducts } from '../hooks/use-recommended-products';
import { RecommendedProductV2M2020 } from './m2020-recommended-product-v2';
import { isGiftCard, isLoyaltyProgram } from './recommended-banner';

export interface RecommendedBannerPropsM2020 {
  title: string;
  results: ReturnType<typeof useRecommendedProducts>;
  className?: string;
}

export function RecommendedBannerV2M2020({
  title,
  results,
  className,
}: RecommendedBannerPropsM2020) {
  return (
    <HomepageProductsCarousel
      className={cn('gap-4 container-responsive lg:gap-6', className)}
      viewableClassName="mx-[-112px] px-[112px]"
      carouselWrapperClassName="gap-4 lg:gap-6"
      titleComponent={
        <p className="font-heading text-base-bold md:text-xl-bold">{title}</p>
      }
    >
      {results.map(({ data }, idx) => {
        if (!data || typeof data === 'function') {
          return null;
        }

        let productCategory = '';
        let productImageUrl = '';
        let productLink = '';

        if (isGiftCard(data)) {
          productImageUrl = data.imageUrls[0];
          productLink = `/products/gift-cards/${data.id}`;
          productCategory = 'Gift Card';
        }

        if (isLoyaltyProgram(data)) {
          productImageUrl =
            data.capabilities[0].secondaryLogoUrl ??
            data.capabilities[0].logoUrl;

          switch (data.category) {
            case 'crypto': {
              productLink = getPathFromLoyaltyProgram('crypto') + data.id;
              break;
            }
            case 'hotel':
            case 'airline': {
              productLink =
                getPathFromLoyaltyProgram('points_transfer') + data.id;
              break;
            }
            case 'voucher': {
              productLink = getPathFromLoyaltyProgram('voucher') + data.id;
              break;
            }
          }

          productCategory = camelCaseToWords(data.category || '');
        }

        return (
          <CarouselItem
            key={`carousel-item-${idx}`}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <RecommendedProductV2M2020
              className="shrink-0 gap-2"
              productName={data.name}
              productType={productCategory}
              productImageUrl={productImageUrl}
              productLink={productLink}
            />
          </CarouselItem>
        );
      })}
      <CarouselItem
        key={`carousel-item-netflix`}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <RecommendedProductV2M2020
          className="shrink-0 gap-2"
          productName={'Netflix'}
          productType={'Streaming Service'}
          productImageUrl={'/assets/m2020/netflix.svg'}
          productLink={'/products/streaming-services/netflix'}
        />
      </CarouselItem>
    </HomepageProductsCarousel>
  );
}
