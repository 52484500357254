import { Offer, OfferLocation } from '@/features/offer/offer.schema';

export function dedupeOfferLocations(offer: Offer): OfferLocation[] {
  if (!offer || !offer.locations) {
    return [];
  }

  // Offer locations come directly from Triple.
  // They do not deduplicate based on lonlat.
  // FE deduplicates for now based on the first entry seen for lonlat.
  // Note: There is no deduplication for offers that are **extremely close, but not exactly at the same spot.**
  const seenIdentifiers = new Set<string>();

  return offer.locations.filter((item) => {
    // There are offers with locations that do not have a lonlat
    // For those, we will use the name as the deduplication key.
    // Note: If an offer with no lonlat shares the same location name with another offer that has a lonlat, then
    // there WILL be duplicates (extreme edge case anyway).
    // https://kaligo.slack.com/archives/CUSBMUU3H/p1722234987307449?thread_ts=1722232373.496439&cid=CUSBMUU3H
    if (!item.lonlat) {
      const nameKey = item.name;

      if (seenIdentifiers.has(nameKey)) {
        return false;
      } else {
        seenIdentifiers.add(nameKey);
        return true;
      }
    }

    const lonlatKey = item.lonlat.join(',');
    if (seenIdentifiers.has(lonlatKey)) {
      return false;
    } else {
      seenIdentifiers.add(lonlatKey);
      return true;
    }
  });
}
